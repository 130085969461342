<template>
    <div class="row">
        <div class="col-xl">
            <div :class="cardClass()">
                <div class="card-body">
                    <div class="clearfix">
                        <div class="float-left">
                            <h4 class="card-title">
                                {{ readableTitle() }}
                            </h4>
                        </div>
                        <div class="float-right">
                            <div v-html="statusBadge()"></div>
                        </div>
                    </div>
                    <div>
                        <p class="card-subtitle mb-2 text-muted">{{ decodedName() }}</p>
                        <div class="card-text">
                            <a v-on:click="triggerDownload()">Request Download</a>
                            <a class="pl-4" v-if="summaryAvailable()" v-on:click="triggerSummaryDownload()">Request Summary</a>
                        </div>
                        <small v-if="loading">Creating presigned url...</small>
                        <small v-if="error" class="text-danger">{{ errorMessage }}</small>
                        <p class="card-text" v-if="presignedUrl">
                            <a :href="presignedUrl">Download</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Status from 'vue-fineuploader/status.vue'
    import * as moment from 'moment-timezone'

    export default {
        components: {
            Status
        },
        name: 'document',
        props: [
            'result',
            'shelterId'
        ],
        data() {
            return {
                loading: false,
                error: false,
                errorMessage: '',
                presignedUrl: null
            }
        },
        methods: {
            readableTitle() {
                let time = moment.tz(this.result.created_at, moment.tz.guess()).format('MMMM Do YYYY, h:mm:ss a z');
                return "Combined " + this.result.report_name + " - " + time;
            },
            cardClass() {
                let vm = this;
                let status = vm.result.status;

                if (status === "uploaded" || status === "processed") {
                    return "card mt-2 border-success";
                } else if (status === "upload_pending" || status === "processing") {
                    return "card mt-2 border-warning";
                } else {
                    return "card mt-2";
                }
            },
            statusBadge: function() {
                let vm = this;
                let status = vm.result.status;

                if (status === "uploaded") {
                    return '<span class="badge badge-success">Uploaded</span>';
                } else if (status === "upload_pending") {
                    return '<span class="badge badge-warning">Upload Pending or Failed</span>';
                } else if (status === "processing") {
                    return '<span class="badge badge-warning">Processing on Lambda</span>';
                } else if (status === "processed") {
                    return '<span class="badge badge-success">Processed</span>';
                }
            },
            decodedName: function() {
                return decodeURIComponent(this.result.name);
            },
            getPresignedUrl: function(summaryReport) {
                let vm = this;
                if (vm.loading) {
                    return;
                }

                vm.loading = true;
                vm.presignedUrl = null;
                let url = "/shelters/" + vm.shelterId + "/documents/" + vm.result.id + "/presigned_url";
                if (summaryReport) {
                    url = url + "?summary_report=true";
                }
                axios.get(url)
                    .then(function (response) {
                        vm.loading = false;
                        vm.presignedUrl = response.data.url;
                    })
                    .catch(function (error) {
                        vm.loading = false;
                        vm.error = true;
                        vm.errorMessage = "Error... ask Matt";
                        console.log(error);
                    })
            },
            triggerDownload: function() {
                this.getPresignedUrl(false);
            },
            triggerSummaryDownload: function() {
                this.getPresignedUrl(true);
            },
            summaryAvailable: function() {
                let vm = this;
                return (vm.result.report_name === 'animal_intake' || vm.result.report_name === 'vaccines');
            }
        }
    }
</script>