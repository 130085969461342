<template>
    <div class="row">
        <div class="col-xl">
            <div :class="cardClass()">
                <div class="card-body">
                    <div class="clearfix">
                        <div class="float-left">
                            <h4 class="card-title">
                                {{ decodedName() }}
                            </h4>
                        </div>
                        <div class="float-right">
                            <div v-if="!uploading && !archived()" v-html="statusBadge()">
                            </div>
                        </div>
                    </div>
                    <div v-if="archived()">
                        <small>Archived</small>
                    </div>
                    <div v-if="!uploading && !archived()">
                        <p class="card-subtitle mb-2 text-muted" v-if="showArchive">Created {{ readableCreatedAt() }}</p>
                        <template v-if="showArchive">
                            <div class="card-text">
                                <a v-on:click="triggerDownload()">Request Download</a>
                                <a v-if="showArchive" data-confirm="Are you sure?" rel="nofollow" data-method="delete" :href="destroyLink()" class="card-link text-danger pl-4">Archive</a>
                            </div>
                            <small v-if="loading">Creating presigned url...</small>
                            <small v-if="error" class="text-danger">{{ errorMessage }}</small>
                            <p class="card-text" v-if="presignedUrl">
                                <a :href="presignedUrl">Download</a>
                            </p>
                        </template>
                    </div>
                    <div class='uploading-container' v-if="uploading">
                        <progress-bar :id="result.file_id" :hideBeforeStart="false" :hideOnComplete="false" :uploader="uploader" class="progress progress-bar-default"></progress-bar>
                        <status :id="result.file_id" :uploader="uploader" class=""></status>
                        <file-size :id="result.file_id" :uploader="uploader" class=""></file-size>
                        <cancel-button :id="result.file_id" :uploader="uploader" class="btn btn-outline btn-danger"></cancel-button>
                        <pause-resume-button :id="result.file_id" :uploader="uploader" class="btn btn-outline btn-default"></pause-resume-button>
                        <retry-button :id="result.file_id" :uploader="uploader" class="btn btn-outline btn-default"></retry-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import CancelButton from 'vue-fineuploader/cancel-button.vue'
    import FileSize from 'vue-fineuploader/filesize.vue'
    import PauseResumeButton from 'vue-fineuploader/pause-resume-button.vue'
    import ProgressBar from 'vue-fineuploader/progress-bar.vue'
    import RetryButton from 'vue-fineuploader/retry-button.vue'
    import Status from 'vue-fineuploader/status.vue'
    //import * as moment from 'moment'
    import * as moment from 'moment-timezone'

    export default {
        components: {
            CancelButton,
            FileSize,
            PauseResumeButton,
            ProgressBar,
            RetryButton,
            Status
        },
        name: 'original-document',
        props: [
            'shelterId',
            'result',
            'uploading',
            'uploader',
            'showArchive'
        ],
        data() {
            return {
                loading: false,
                error: false,
                errorMessage: '',
                presignedUrl: null
            }
        },
        methods: {
            readableCreatedAt() {
                return moment.tz(this.result.created_at, moment.tz.guess()).format('MMMM Do YYYY, h:mm:ss a z');
            },
            cardClass() {
                let vm = this;
                if (vm.archived()) {
                    return "card mt-2 text-muted";
                } else if (vm.uploadPending()) {
                    return "card mt-2 border-warning";
                } else if (vm.uploaded()) {
                    return "card mt-2 border-success";
                } else {
                    return "card mt-2";
                }
            },
            archived: function() {
                return this.result.status === "archived";
            },
            uploadPending() {
                return this.result.status === "upload_pending";
            },
            uploaded() {
                return this.result.status === "uploaded";
            },
            destroyLink: function() {
                return "/shelters/" + this.shelterId + "/original_documents/" + this.result.id;
            },
            statusBadge: function() {
                let vm = this;
                if (vm.result.status === "uploaded") {
                    return '<span class="badge badge-success">Uploaded</span>';
                } else if (vm.result.status === "upload_pending") {
                    return '<span class="badge badge-warning">Upload Pending or Failed</span>';
                } else if (vm.result.status === "processing") {
                    return '<span class="badge badge-info">Processing on Lambda</span>';
                } else if (vm.result.status === "processed") {
                    return '<span class="badge badge-info">Processed</span>';
                }
            },
            decodedName: function() {
                return decodeURIComponent(this.result.name);
            },
            triggerDownload: function() {
                let vm = this;
                if (vm.loading) {
                    return;
                }

                vm.loading = true;
                vm.presignedUrl = null;
                let url = "/shelters/" + vm.shelterId + "/original_documents/" + vm.result.id + "/presigned_url";
                axios.get(url)
                    .then(function (response) {
                        vm.loading = false;
                        vm.presignedUrl = response.data.url;
                    })
                    .catch(function (error) {
                        vm.loading = false;
                        vm.error = true;
                        vm.errorMessage = "Error... ask Matt";
                        console.log(error);
                    })
            },
        }
    }
</script>