/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue/dist/vue.esm'
import _ from 'lodash'
import FinishedUploading from './finished_uploading'
import ReportUploader from './report_uploader'
import Document from './document'
import OriginalDocument from './original_document'
import List from './list'
import Spinner from './spinner'
import UploadedDocuments from './uploaded_documents'

document.addEventListener('DOMContentLoaded', () => {
    // Create a global Event Bus
    const NotificationManager = new Vue();

    // Add to Vue properties by exposing a getter for $bus
    Object.defineProperties(Vue.prototype, {
        $notificationManager: {
            get: function () {
                return NotificationManager;
            }
        }
    });

    const app = new Vue({
        el: '#app',
        components: {
            FinishedUploading,
            List,
            ReportUploader,
            Document,
            Spinner,
            OriginalDocument,
            UploadedDocuments,
        },
    });
});
