<template>
    <div class="sk-double-bounce">
        <div class="sk-child sk-double-bounce1"></div>
        <div class="sk-child sk-double-bounce2"></div>
    </div>
</template>

<script>
    export default {
        name: 'spinner',
    }
</script>