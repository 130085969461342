<template>
    <div class="report-section mb-5">
        <div class="row">
            <div class="col">
                <h2>
                    {{ reportName }}
                </h2>
            </div>
            <div class="col-sm-1">
                <button v-on:click="triggerLambda()" class="btn btn-primary" :disabled="loading" v-if="resultCount > 0">Combine</button>
                <small class="text-danger" v-if="error">{{ errorMessage }}</small>
                <div class="mt-4">
                    <small>
                        <a v-if="resultCount > 0" :data-confirm="allConfirmMessage()" rel="nofollow"
                           data-method="delete" :href="destroyAllLink()" class="text-danger">
                            Archive All {{ reportName }}
                        </a>
                    </small>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-10 offset-sm-1">
                <div class="row">
                    <div class="col-sm-12">
                        <div v-if="combinedResultCount !== null">
                            <p class="mb-0">
                                {{ combinedResultCount }} combined files
                                <a v-on:click="toggleCombinedVisibilityClicked()">{{ getCombinedVisibilityName() }}</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row" v-show="showAllCombined">
                    <div class="col-sm-12">
                        <list :url="combinedUrl()" :report-name="reportName" model-name="Document">
                            <template slot="resultList" slot-scope="props">
                                <document
                                        v-bind:result="props.result"
                                        v-bind:key="props.result.id"
                                        v-bind:shelter-id="shelterId"
                                >
                                </document>
                            </template>
                        </list>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div v-if="resultCount !== null">
                            <small>{{ resultCount }} original files</small>
                            <a v-on:click="toggleVisibilityClicked()">{{ getVisibilityName() }}</a>
                        </div>
                    </div>
                </div>
                <div class="row" v-show="showAll">
                    <div class="col-sm-12">
                        <list :url="url" :report-name="reportName" model-name="OriginalDocument">
                            <template slot="resultList" slot-scope="props">
                                <original-document
                                        v-bind:result="props.result"
                                        v-bind:key="props.result.id"
                                        v-bind:uploading="false"
                                        v-bind:shelter-id="shelterId"
                                        v-bind:show-archive="true"
                                >
                                </original-document>
                            </template>
                        </list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Document from './document'
    import OriginalDocument from './original_document'
    import List from './list'

    export default {
        components: {
            Document,
            OriginalDocument,
            List
        },
        name: 'uploaded-documents',
        props: [
            'reportName',
            'shelterId',
            'url'
        ],
        data() {
            return {
                loading: false,
                error: false,
                errorMessage: '',

                resultCount: null,
                combinedResultCount: null,
                showAllCombined: false,
                showAll: false,
            }
        },
        mounted() {
            let vm = this;
            vm.$on('listLoaded', (totalCount, reportName, modelName) => {
                if (vm.reportName === reportName) {
                    if (modelName === "OriginalDocument") {
                        vm.resultCount = totalCount;
                    } else if (modelName === "Document") {
                        vm.combinedResultCount = totalCount;
                    }
                }
            });
        },
        methods: {
            allConfirmMessage() {
                return "Are you sure you want to archive all " + this.reportName + "?"
            },
            combinedUrl: function() {
                return "/shelters/"+ this.shelterId +"/documents?report_name=" + this.reportName;
            },
            toggleCombinedVisibilityClicked: function() {
                let vm = this;
                vm.showAllCombined = !vm.showAllCombined;
            },
            getCombinedVisibilityName: function() {
                let vm = this;
                if (vm.showAllCombined) {
                    return "Hide";
                }

                return "Show";
            },
            toggleVisibilityClicked: function() {
                let vm = this;
                vm.showAll = !vm.showAll;
            },
            getVisibilityName: function() {
                let vm = this;
                if (vm.showAll) {
                    return "Hide";
                }

                return "Show";
            },
            destroyAllLink: function() {
                return "/shelters/" + this.shelterId + "/original_documents/destroy_all?report_name=" + this.reportName;
            },
            triggerLambda: function() {
                // Here I need to trigger the lambda function for this report
                let vm = this;
                if (vm.loading) {
                    return;
                }

                vm.loading = true;
                vm.error = false;

                let url = "/shelters/" + vm.shelterId + "/documents";
                let params = {
                    report_name: vm.reportName,
                };
                axios.post(url, params)
                    .then(function (response) {
                        // Update the documents list, or trigger a refresh of a list or something
                        vm.loading = false;
                        vm.$notificationManager.$emit('combineTriggered', vm.reportName, "Document");
                    })
                    .catch(function (error) {
                        vm.loading = false;
                        vm.error = true;
                        vm.errorMessage = "Something bad happened... ask Matt";
                    });
            }
        }
    }
</script>