<template>
    <div class="finished-uploading">
        <div v-show="currentlyFinished">
            <h2>Thank you!</h2>
            <p>Click finish by mistake? <a v-on:click="undoFinished()">Click here</a> to upload more documents.</p>
            <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
        </div>
        <div v-show="!currentlyFinished">
            <div class="alert alert-outline-success">
                <h3>Finished Uploading?</h3>
                <p>Please click the button below when you are finished, so we know you are ready to import. Thank you!</p>
                <button v-on:click="finishedPressed()" class="btn btn-success">
                    All Files Uploaded
                    <spinner v-if="loading"></spinner>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import Spinner from "./spinner";

    export default {
        components: {
            Spinner

        },
        name: 'list',
        props: [
            'shelterId',
            'finished'
        ],
        data: function() {
            return {
                loading: false,
                error: false,
                errorMessage: '',
                currentlyFinished: null,
            }
        },
        created: function() {
            let vm = this;
            // Move from prop to real data
            vm.currentlyFinished = vm.finished;
        },
        methods: {
            finishedPressed() {
                let vm = this;
                vm.currentlyFinished = true;
                vm.updateShelter();
            },
            undoFinished() {
                let vm = this;
                vm.currentlyFinished = false;
                vm.updateShelter();
            },
            updateShelter() {
                let vm = this;
                let params = {
                    shelter: {
                        finished_uploading: vm.currentlyFinished
                    }
                };
                vm.loading = true;
                let url = "/shelters/" + vm.shelterId;
                axios.put(url, params)
                    .then(function (response) {
                        vm.loading = false;
                        vm.$notificationManager.$emit('shelter-finished-updated', vm.shelterId, vm.currentlyFinished);
                    })
                    .catch(function (error) {
                        vm.error = true;
                        vm.errorMessage = "Something went wrong, please try again later.";
                    });
            }
        }
    }
</script>
