<template>
    <div class="list">
        <div v-if="loading">
            Loading... <spinner></spinner>
        </div>
        <small v-show="error"><span class="text-danger">{{ errorMessage }}</span></small>
        <div v-show="resultsLoaded">
            <slot name="resultList"
                  v-for="result in results"
                  :result="result">
            </slot>
        </div>
    </div>
</template>

<script>
    import Spinner from "./spinner";

    export default {
        components: {
            Spinner

        },
        name: 'list',
        props: [
            'url',
            'reportName',
            'modelName'
        ],
        data: function() {
            return {
                loading: false,
                error: false,
                errorMessage: '',
                resultsLoaded: false,
                results: []
            }
        },
        created: function() {
            let vm = this;
            vm.getList();

            vm.$notificationManager.$on('combineTriggered', vm.handleCombine);
        },
        destroyed: function() {
            vm.$notificationManager.$off('combineTriggered', vm.handleCombine);
        },
        methods: {
            handleCombine(reportName, modelName) {
                let vm = this;
                if (vm.reportName === reportName && vm.modelName === modelName) {
                    vm.getList();
                }
            },
            resultCount: function() {
                return this.results.length;
            },
            getList: function() {
                let vm = this;
                vm.resetListResults();

                vm.loading = true;
                let url = vm.url;
                axios.get(url)
                    .then(function (response) {
                        vm.loading = false;

                        vm.results = response.data;
                        vm.resultsLoaded = true;
                        vm.$parent.$emit('listLoaded', vm.resultCount(), vm.reportName, vm.modelName);
                    })
                    .catch(function (error) {
                        vm.resetListResults();
                        vm.errorMessage = "Error retrieving " + vm.responseKey;
                        vm.error = true;
                    })
            },
            resetListResults: function() {
                let vm = this;
                vm.loading = false;

                vm.resultsLoaded = false;
                vm.results = [];

                vm.error = false;
                vm.errorMessage = '';
            },
        }
    }
</script>
