<template>
    <div class="upload-section mt-4" v-show="!finished">
        <div class="row">
            <div class="col">
                <slot name="headerTitle"></slot>
                <div v-show="showAll">
                    <slot name="headerContent" v-show="showAll"></slot>
                </div>
            </div>
            <div class="col-sm-1">
                <a v-on:click="toggleVisibilityClicked()">{{ getVisibilityName() }}</a>
            </div>
        </div>
        <div v-show="showAll" class="mb-5">
            <dropzone class="dropzone"
                      :qq-drop-area-text="dropzoneText()"
                      :multiple="true"
                      :uploader="uploader">
            </dropzone>
            <div class="row" v-if="error">
                <div class="col">
                    <small class="text-danger">{{ errorMessage }}</small>
                </div>
            </div>
            <div class="upload-results" role="region" aria-live="polite" aria-relevant="additions removals">
                <original-document v-for="document in state.uploadingDocs" :key="document.file_id" :result="document" :uploading="true" :uploader="uploader" :shelter-id="shelterId">
                </original-document>
            </div>
            <list :url="getListURL()">
                <template slot="resultList" slot-scope="props">
                    <original-document
                            v-bind:result="props.result"
                            v-bind:key="props.result.id"
                            v-bind:uploading="false"
                            v-bind:shelter-id="shelterId"
                            v-bind:show-archive="false"
                    >
                    </original-document>
                </template>
            </list>
        </div>
        <hr/>
    </div>
</template>

<script>
    import Dropzone from 'vue-fineuploader/dropzone'
    import FineUploaderS3 from 'fine-uploader-wrappers/s3'
    import RSVP from 'rsvp'
    import OriginalDocument from './original_document'
    import List from './list'

    export default {
        components: {
            Dropzone,
            OriginalDocument,
            List
        },
        name: 'report-uploader',
        props: [
            'shelterId',
            'shelterFinished',
            'reportName',
            'allowedExtensions',
            'endpoint', // The s3 bucket url
            'accessKey' // The s3 access key to upload
        ],
        data() {
            let vm = this;

            const getS3Key = function(fileId) {
                let fileName = encodeURIComponent(vm.uploader.methods.getName(fileId));
                let keyPost = new RSVP.Promise(function(resolve, reject) {
                    let params = {
                        original_document: {
                            name: fileName,
                            shelter_id: vm.shelterId,
                            report_name: vm.reportName
                        }
                    };
                    let url = "/shelters/" + vm.shelterId + "/original_documents";
                    axios.post(url, params)
                        .then(function (response) {
                            vm.appendJsonToUploadList(fileId, response.data);
                            resolve(response.data.s3_file_name);
                        })
                        .catch(function (error) {
                            reject(error);
                        });
                });
                return keyPost;
            };

            const s3Uploader = new FineUploaderS3({
                options: {
                    maxConnections: 1500,
                    chunking: {
                        enabled: false
                    },
                    objectProperties: {
                        key: getS3Key
                    },
                    request: {
                        endpoint: vm.endpoint,
                        accessKey: vm.accessKey
                    },
                    resume: {
                        enabled: true
                    },
                    retry: {
                        enableAuto: true
                    },
                    signature: {
                        endpoint: "/original_documents/upload_signature",
                        customHeaders: {
                            'X-CSRF-TOKEN': document.querySelector("meta[name=csrf-token]").content
                        }
                    },
                    uploadSuccess: {
                        endpoint: "/original_documents/upload_success",
                        customHeaders: {
                            'X-CSRF-TOKEN': document.querySelector("meta[name=csrf-token]").content
                        }
                    },
                    validation: {
                        allowedExtensions: vm.allowedExtensionsWithoutPrefix(),
                        itemLimit: 1500
                    }
                }
            });

            return {
                finished: false,

                loading: false,
                error: false,
                errorMessage: false,

                showAll: false,
                // using a state variable to handle Vue data reactivity
                state: {
                    uploadingDocs: [],
                },
                uploader: s3Uploader
            }
        },
        methods: {
            getListURL: function() {
                return "/shelters/" + this.shelterId + "/original_documents?report_name=" + this.reportName;
            },
            allowedExtensionsWithoutPrefix: function() {
                return this.allowedExtensions.map(ext => ext.slice(1));
            },
            dropzoneText: function() {
                return "Drop " + this.allowedExtensions.join(" or ") + " files here";
            },
            appendJsonToUploadList: function(fileId, json) {
                let vm = this;
                const uploadingDocs = vm.state.uploadingDocs;
                json.file_id = fileId;
                uploadingDocs.push(json);
                vm.$set(this.state, 'uploadingDocs', uploadingDocs);
            },
            removeFileIfExists: function(fileId) {
                let vm = this;
                const uploadingDocs = vm.state.uploadingDocs;
                let indexToRemove = null;

                for (let doc of uploadingDocs) {
                    if (doc.file_id === fileId) {
                        indexToRemove = uploadingDocs.indexOf(doc);
                    }
                }

                // Vue gotcha #1 Vue cannot detect changes in array/objects
                // Use this special $set method to do it
                uploadingDocs.splice(indexToRemove, 1);
                vm.$set(vm.state, 'uploadingDocs', uploadingDocs);
            },
            toggleVisibilityClicked: function() {
                let vm = this;
                vm.showAll = !vm.showAll;
            },
            getVisibilityName: function() {
                return this.showAll ? "Hide" : "Show";
            },
            isFileRemoved(status) {
                // Check if the user deleted or cancelled an upload
                return [
                    'canceled',
                    'deleted'
                ].indexOf(status) >= 0
            },
            handleShelterFinished(shelterId, finished) {
                let vm = this;
                if (vm.shelterId === shelterId) {
                    vm.finished= finished;
                }
            }
        },
        mounted() {
            let vm = this;
            vm.uploader.on('statusChange', (id, oldStatus, newStatus) => {
                // Handle cancelled or deleted uploads
                if (vm.isFileRemoved(newStatus)) {
                    vm.removeFileIfExists(id);
                } else if (newStatus === "rejected") {
                    vm.errorMessage = "File rejected are you sure it is a " + this.allowedExtensions.join(" or ") + " file?";
                    vm.error = true;
                    setTimeout(function(){ vm.error = false; }, 5000);
                }
            });

            vm.finished = vm.shelterFinished;
            vm.$notificationManager.$on('shelter-finished-updated', vm.handleShelterFinished);
        },
        destroyed() {
            vm.$notificationManager.$off('shelter-finished-updated', this.handleShelterFinished);
        },
        watch: {
            state: function (newState) {
                // Enable a 'are you sure you want to leave' prompt if things are uploading
                if (newState.uploadingDocs.length > 0) {
                    window.onbeforeunload = function() {
                        return true;
                    }
                } else {
                    window.onbeforeunload = null;
                }
            }
        }
    }
</script>